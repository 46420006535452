import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';

// Importe suas páginas aqui
import FretzHomePage from './app/page';
import ComoFunciona from './ComoFunciona';
import Precos from './Precos';
import RegioesAtendidas from './RegioesAtendidas';
import RegisterDriver from './RegisterDriver';
import ProcessPayment from './ProcessPayment';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<FretzHomePage />} />
          <Route path="/como-funciona" element={<ComoFunciona />} />
          <Route path="/precos" element={<Precos />} />
          <Route path="/regioes-atendidas" element={<RegioesAtendidas />} />
          <Route path="/quero-ser-entregador" element={<RegisterDriver />} />
          <Route path="/process_payment" element={<ProcessPayment />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
