import React from 'react';
import Header from './app/Header';
import Footer from './app/Footer';

const RegioesAtendidas = () => {
  return (
    <div>
      <Header />
      <main className="container mx-auto p-4">
        <div className='regioes-atendidas'>
          <h1 className="pagetitleh1">Regiões Atendidas pela Fretz</h1>
          <p>Atualmente, estamos disponíveis em Goiânia nos seguintes bairros:</p>
          <ul>
            <li>Jardim América</li>
            <li>Setor Marista</li>
            <li>Setor Bueno</li>
            <li>Setor Oeste</li>
            <li>Setor Jardim Goiás</li>
            <li>Setor Parque Amazônia</li>
            <li>Setor Serrinha</li>
            <li>Setor Pedro Ludovico</li>
          </ul>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default RegioesAtendidas;
