import React, { useState } from 'react';
import Header from './app/Header';
import Footer from './app/Footer';
import supabase from './utils/supabaseClient';
import { toast } from 'react-toastify';

const RegisterDriver = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    whatsapp: '',
    city: '',
    vehicle: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { firstName, lastName, email, whatsapp, city, vehicle } = formData;
    if (!firstName || !lastName || !email || !whatsapp || !city || !vehicle) {
      toast.error('Preencha todas as informações e envie novamente.');
      return;
    }

    const { error } = await supabase.from('entregadores').insert([
      {
        primeiro_nome: firstName,
        sobrenome: lastName,
        email,
        whatsapp,
        cidade: city,
        tipo_veiculo: vehicle
      }
    ]);

    if (error) {
      toast.error('Erro ao enviar o formulário.');
    } else {
      toast.success('Formulário enviado com sucesso!');
      setFormData({ firstName: '', lastName: '', email: '', whatsapp: '', city: '', vehicle: '' });
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      
      <Header />
      <main className="container mx-auto p-4 formhome">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h1 className="pagetitleh1 text-fretz-purple">Quero ser Entregador</h1>
          <p>Seja um entregador da Fretz e aumente sua renda mensal</p>
          <form onSubmit={handleSubmit} className="register-form">
            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="Nome" className="pickup-input" />
            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Sobrenome" className="pickup-input" />
            <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="E-mail" className="pickup-input" />
            <input type="text" name="whatsapp" value={formData.whatsapp} onChange={handleChange} placeholder="Whatsapp" className="pickup-input" />
            <input type="text" name="city" value={formData.city} onChange={handleChange} placeholder="Cidade" className="pickup-input" />
            <select name="vehicle" value={formData.vehicle} onChange={handleChange} className="pickup-input">
              <option value="">Tipo de veículo</option>
              <option value="smallPickup">Picape Pequena</option>
              <option value="van">Van</option>
              <option value="truck">Camionete</option>
              <option value="bigTruck">Caminhão</option>
              <option value="motorcycle">Moto</option>
              <option value="car">Carro de Passeio</option>
              <option value="van">Furgão</option>
            </select>
            <button type="submit" className="bg-gradient-to-b from-fretz-start to-fretz-end text-white p-4 px-14 font-bold rounded hover:bg-gradient-to-r active:bg-gradient-to-t">Cadastrar</button>
          </form>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default RegisterDriver;
