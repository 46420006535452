import React from 'react';
import Header from './app/Header'; // Ajuste o caminho conforme necessário
import Footer from './app/Footer'; // Ajuste o caminho conforme necessário
import FooterCopyright from './app/FooterCopyright';

const Precos = () => {
  return (
    <div>
      <Header />
      <main className="container mx-auto p-4">
        <h1 className="pagetitleh1 text-center">Tabela de Serviços Fretz</h1>
        <div className="precos-table-container mt-8">
          <table className="precos-table">
            <thead>
              <tr>
                <th>Serviço</th>
                <th>Descrição</th>
                <th>Tarifa</th>
                <th>Detalhes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Fretz Agora</td>
                <td>Simule e contrate uma coleta/entrega imediatamente</td>
                <td>a partir de R$ 12</td>
                <td>Sob demanda</td>
              </tr>
              <tr>
                <td>Fretz Office</td>
                <td>Coleta/entrega com privacidade para escritórios de advocacia, contabilidade e outros</td>
                <td>a partir de R$ 12</td>
                <td>Sob demanda ou Mensal</td>
              </tr>
              <tr>
                <td>Fretz Econômico</td>
                <td>Coleta em rota otimizada em até 24 horas, entrega em até 24 horas após a coleta</td>
                <td>a partir de R$ 12</td>
                <td>Mais barato</td>
              </tr>
              <tr>
                <td>Fretz Cargo</td>
                <td>Picapes, saveiro, strada, e camionetes para pequenas mudanças</td>
                <td>a partir de R$ 12</td>
                <td>Pequenas mudanças</td>
              </tr>
              <tr>
                <td>Fretz Multi</td>
                <td>Para 3 ou mais trechos, entregas de brindes ou presentes para vários clientes numa ocasião especial</td>
                <td>a partir de R$ 12</td>
                <td>Rotas otimizadas</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
      <Footer />
      <FooterCopyright />
    </div>
  );
};

export default Precos;
