import React from 'react';
import Header from './app/Header';
import Footer from './app/Footer';
import FooterCopyright from './app/FooterCopyright';

const ComoFunciona = () => {
  return (
    <div>
      <Header />
      <main className="container mx-auto p-4">
        {/* Conteúdo da página Como Funciona */}
        <h1 className="pagetitleh1 text-center">Como Funciona</h1>
        <div className="steps-container mt-8">
          <div className="step">
            <div className="step-number">1</div>
            <div className="step-content">
              <h2 className="step-title">Faça uma SIMULAÇÃO</h2>
              <p>Veja os valores da entrega</p>
            </div>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <div className="step-content">
              <h2 className="step-title">Efetue o PAGAMENTO</h2>
              <p>Complete o pagamento para confirmar sua entrega</p>
            </div>
          </div>
          <div className="step">
            <div className="step-number">3</div>
            <div className="step-content">
              <h2 className="step-title">PRONTO!</h2>
              <p>A coleta e a entrega serão feitas no prazo combinado!</p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <FooterCopyright />
    </div>
  );
};

export default ComoFunciona;
