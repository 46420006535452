import React, { useState, useRef, useEffect } from 'react';
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Header from './Header';
import Footer from './Footer';
import FooterCopyright from '../app/FooterCopyright';
import supabase from '../utils/supabaseClient'; // Ajuste o caminho conforme necessário


const FretzHomePage = () => {
    const [pickupAddress, setPickupAddress] = useState('');
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [isReturnTrip, setIsReturnTrip] = useState(false);
    const [distance, setDistance] = useState(0);
    const [duration, setDuration] = useState(0);
    const [price, setPrice] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [visitorName, setVisitorName] = useState('');
    const [visitorEmail, setVisitorEmail] = useState('');
    const [visitorWhatsapp, setVisitorWhatsapp] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [directions, setDirections] = useState(null);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const libraries = ["places", "directions"];

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries, // Use a constante definida
    });

    const pickupRef = useRef(null);
    const deliveryRef = useRef(null);
    const mapRef = useRef(null);
    const directionsRendererRef = useRef(null);
    const pickupMarkerRef = useRef(null);
    const deliveryMarkerRef = useRef(null);

    const goianiaBounds = {
        north: -16.568, // latitude máxima de Goiânia
        south: -16.742, // latitude mínima de Goiânia
        east: -49.176,  // longitude máxima de Goiânia
        west: -49.409   // longitude mínima de Goiânia
    };

    const isWithinGoiania = (location) => {
        return (
            location.lat() <= goianiaBounds.north &&
            location.lat() >= goianiaBounds.south &&
            location.lng() <= goianiaBounds.east &&
            location.lng() >= goianiaBounds.west
        );
    };

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const { latitude, longitude } = position.coords;
                const geocoder = new window.google.maps.Geocoder();
                const latlng = { lat: latitude, lng: longitude };
                geocoder.geocode({ location: latlng }, (results, status) => {
                    if (status === 'OK') {
                        if (results[0]) {
                            setPickupAddress(results[0].formatted_address);
                        } else {
                            console.error('Nenhum resultado encontrado');
                        }
                    } else {
                        console.error('Erro na geolocalização: ' + status);
                    }
                });
            });
        }
    }, [isLoaded]);

    useEffect(() => {
        if (isLoaded) {
            if (!mapRef.current) {
                mapRef.current = new window.google.maps.Map(document.getElementById('map'), {
                    center: { lat: -15.7797, lng: -47.9297 },
                    zoom: 12,
                });
            }

            if (pickupAddress && !directions) {
                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({ address: pickupAddress }, (results, status) => {
                    if (status === 'OK') {
                        if (!pickupMarkerRef.current) {
                            pickupMarkerRef.current = new window.google.maps.Marker({
                                map: mapRef.current,
                            });
                        }
                        pickupMarkerRef.current.setPosition(results[0].geometry.location);
                        mapRef.current.setCenter(results[0].geometry.location);
                    } else {
                        console.error('Geocode error: ' + status);
                    }
                });
            }

            if (deliveryAddress && !directions) {
                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({ address: deliveryAddress }, (results, status) => {
                    if (status === 'OK') {
                        if (!deliveryMarkerRef.current) {
                            deliveryMarkerRef.current = new window.google.maps.Marker({
                                map: mapRef.current,
                            });
                        }
                        deliveryMarkerRef.current.setPosition(results[0].geometry.location);
                        mapRef.current.setCenter(results[0].geometry.location);
                    } else {
                        console.error('Geocode error: ' + status);
                    }
                });
            }
        }

        if (isLoaded && directions) {
            if (!directionsRendererRef.current) {
                directionsRendererRef.current = new window.google.maps.DirectionsRenderer();
                directionsRendererRef.current.setMap(mapRef.current);
            }
            directionsRendererRef.current.setDirections(directions);
        }
    }, [isLoaded, pickupAddress, deliveryAddress, directions]);

    const onPickupPlaceChanged = () => {
        if (pickupRef.current) {
            const place = pickupRef.current.getPlace();
            if (place && place.formatted_address) {
                setPickupAddress(place.formatted_address);
            }
        }
    };

    const onDeliveryPlaceChanged = () => {
        if (deliveryRef.current) {
            const place = deliveryRef.current.getPlace();
            if (place && place.formatted_address) {
                setDeliveryAddress(place.formatted_address);
            }
        }
    };

    const calculateFare = async () => {
        setError('');
        if (!pickupAddress || !deliveryAddress) {
            setError('Por favor, preencha ambos os endereços.');
            return;
        }

        if (pickupAddress && deliveryAddress && window.google) {
            setLoading(true);
            const service = new window.google.maps.DistanceMatrixService();
            try {
                const response = await new Promise((resolve, reject) => {
                    service.getDistanceMatrix({
                        origins: [pickupAddress],
                        destinations: [deliveryAddress],
                        travelMode: 'DRIVING',
                    }, (response, status) => {
                        if (status === 'OK') {
                            resolve(response);
                        } else {
                            reject('Erro ao calcular a matriz de distância: ' + status);
                        }
                    });
                });

                const results = response.rows[0].elements[0];
                let distanceInKm = results.distance.value / 1000;
                let durationInMin = results.duration.value / 60;

                // Dobrar a duração se isReturnTrip for verdadeiro
                if (isReturnTrip) {
                    durationInMin *= 2;
                }

                let calculatedPrice = (distanceInKm * 1) + (durationInMin * 0.10);

                if (isReturnTrip) {
                    calculatedPrice *= 2;
                    calculatedPrice = Math.max(calculatedPrice, 24);
                } else {
                    calculatedPrice = Math.max(calculatedPrice, 12);
                }

                if (calculatedPrice < 12) calculatedPrice = 12;

                setDistance(distanceInKm);
                setDuration(durationInMin);
                setPrice(calculatedPrice);

                // Adicionando a chamada ao Supabase para gravar os dados
                const { error } = await supabase.from('simulacoes_entrega').insert([
                    {
                        endereco_coleta: pickupAddress,
                        endereco_entrega: deliveryAddress,
                        distancia_km: distanceInKm,
                        tempo_estimado_min: durationInMin,
                        preco: calculatedPrice,
                        retorno: isReturnTrip,
                        data_hora: new Date().toISOString()
                    }
                ]);

                if (error) {
                    console.error('Erro ao gravar a simulação:', error);
                }

                // Calculando a rota
                const directionsService = new window.google.maps.DirectionsService();
                const directionsResponse = await directionsService.route({
                    origin: pickupAddress,
                    destination: deliveryAddress,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                });

                setDirections(directionsResponse);

            } catch (error) {
                console.error('Erro:', error);
                setError('Erro ao calcular a tarifa. Tente novamente.');
            } finally {
                setLoading(false);
            }
        } else {
            setError('Os endereços não estão definidos ou Google Maps API ainda não foi carregada.');
        }
    };

    const requestDelivery = async () => {
        if (!visitorName || !visitorEmail || !visitorWhatsapp) {
            setError('Por favor, preencha todos os campos de contato.');
            return;
        }

        try {
            const geocoder = new window.google.maps.Geocoder();
            const geocodeResult = await new Promise((resolve, reject) => {
                geocoder.geocode({ address: deliveryAddress }, (results, status) => {
                    if (status === 'OK') {
                        resolve(results[0]);
                    } else {
                        reject('Erro ao geocodificar o endereço de entrega: ' + status);
                    }
                });
            });

            const isDeliveryInGoiania = isWithinGoiania(geocodeResult.geometry.location);

            if (!isDeliveryInGoiania) {
                setConfirmationMessage('Ainda não estamos disponíveis na região solicitada, mas vamos te notificar assim que estivermos.');
                return;
            }

            const { error } = await supabase.from('solicitacoes_entrega').insert([
                {
                    nome: visitorName,
                    email: visitorEmail,
                    whatsapp: visitorWhatsapp,
                    endereco_coleta: pickupAddress,
                    endereco_entrega: deliveryAddress,
                    distancia_km: distance,
                    tempo_estimado_min: duration,
                    preco: price,
                    retorno: isReturnTrip,
                    data_hora: new Date().toISOString()
                }
            ]);

            if (error) {
                console.error('Erro ao gravar a solicitação:', error);
                setError('Erro ao gravar a solicitação. Tente novamente.');
            } else {
                setConfirmationMessage('Dados recebidos com sucesso! Entraremos em contato em alguns minutos.');
                setVisitorName('');
                setVisitorEmail('');
                setVisitorWhatsapp('');
                setIsConfirmed(false);
            }
        } catch (error) {
            console.error('Erro:', error);
            setError('Erro ao processar a solicitação. Tente novamente.');
        }
    };

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading Maps...</div>;
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <Header />
            
            <main className="container mx-auto p-4 formhome">
                <div className="flex flex-col lg:flex-row">
                    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full lg:w-1/2">
                        <h2 className="hometitleh2 text-fretz-purple">Simular e solicitar uma entrega rápida</h2>
                        
                        {error && <div className="mb-4 text-red-500">{error}</div>}
                        
                        <div className="mb-4">
                            <label className="pickup-label block text-gray-700 text-sm font-bold mb-2" htmlFor="pickup-address">
                                Onde a encomenda será coletada?
                            </label>
                            <Autocomplete onLoad={ref => pickupRef.current = ref} onPlaceChanged={onPickupPlaceChanged}>
                                <input id="pickup-address" className="pickup-input w-full p-2 border border-gray-300 rounded" type="text" value={pickupAddress} onChange={(e) => setPickupAddress(e.target.value)} placeholder="Endereço de coleta (Ex.: Rua, 123 ou CEP)" />
                            </Autocomplete>
                        </div>
                        <div className="mb-4">
                            <label className="delivery-label block text-gray-700 text-sm font-bold mb-2" htmlFor="delivery-address">
                               Onde a encomenda será entregue?
                            </label>
                            <Autocomplete onLoad={ref => deliveryRef.current = ref} onPlaceChanged={onDeliveryPlaceChanged}>
                                <input id="delivery-address" className="delivery-input w-full p-2 border border-gray-300 rounded" type="text" value={deliveryAddress} onChange={(e) => setDeliveryAddress(e.target.value)} placeholder="Endereço de destino (Ex.: Rua, 123 ou CEP)" />
                            </Autocomplete>
                        </div>
                        <div className="mb-4 text-left">
                            <label className="inline-flex items-center">
                                <input type="checkbox" checked={isReturnTrip} onChange={(e) => setIsReturnTrip(e.target.checked)} className="form-checkbox" />
                                <span className="ml-2">ADICIONAR RETORNO ao ponto de coleta</span>
                            </label>
                        </div>
                        <button onClick={calculateFare} className="bg-gradient-to-b from-fretz-start to-fretz-end text-white p-4 px-14 font-bold rounded hover:bg-gradient-to-r active:bg-gradient-to-t">
                            {loading ? 'Calculando...' : 'CALCULAR'}
                        </button>

                        {price !== null && (
                            <div className="mt-4 p-4 bg-gray-100 border border-gray-300 rounded">
                                <p>Distância: {distance.toFixed(2)} km</p>
                                <p>Tempo Total Estimado: {duration.toFixed(2)} min</p>
                                <p>Preço: R$ {price.toFixed(2)}</p>

                                <div className="mt-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="visitor-name">
                                        Nome
                                    </label>
                                    <input id="visitor-name" className="w-full p-2 border border-gray-300 rounded" type="text" value={visitorName} onChange={(e) => setVisitorName(e.target.value)} placeholder="Seu nome" />

                                    <label className="block text-gray-700 text-sm font-bold mb-2 text-left mt-4" htmlFor="visitor-email">
                                        E-mail
                                    </label>
                                    <input id="visitor-email" className="w-full p-2 border border-gray-300 rounded" type="email" value={visitorEmail} onChange={(e) => setVisitorEmail(e.target.value)} placeholder="Seu e-mail" />

                                    <label className="block text-gray-700 text-sm font-bold mb-2 text-left mt-4" htmlFor="visitor-whatsapp">
                                        WhatsApp
                                    </label>
                                    <input id="visitor-whatsapp" className="w-full p-2 border border-gray-300 rounded" type="text" value={visitorWhatsapp} onChange={(e) => setVisitorWhatsapp(e.target.value)} placeholder="Seu WhatsApp" />
                                </div>

                                <div className="mt-4 text-left">
                                    <label className="inline-flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={isConfirmed}
                                            onChange={(e) => setIsConfirmed(e.target.checked)}
                                            className="form-checkbox"
                                        />
                                        <span className="ml-2">
                                            Confirmo que minha encomenda possui valor menor do que R$ 500,00 e as dimensões (tamanho e peso) não ultrapassam os 
                                            <span data-tooltip-id="limitsTooltip" data-tooltip-content="A encomenda não pode ter valor maior do que R$ 500,00. Em caso de perda, furto, roubo ou acidente, será ressarcido o valor da encomenda limitado a R$ 500,00 de acordo com as regras disponíveis nos termos de uso e no contrato de prestação de serviços da Fretz." className="ml-1 text-blue-500 cursor-pointer">limites</span>.
                                        </span>
                                    </label>
                                    <ReactTooltip
                                        id="limitsTooltip"
                                        place="right"
                                        effect="solid"
                                        style={{ maxWidth: '200px', whiteSpace: 'pre-wrap' }}
                                    />
                                </div>


                                <button
                                    onClick={requestDelivery}
                                    disabled={!isConfirmed}
                                    className={`mt-4 bg-gradient-to-b from-fretz-start to-fretz-end text-white p-4 px-14 font-bold rounded hover:bg-gradient-to-r active:bg-gradient-to-t ${!isConfirmed ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    SOLICITAR ENTREGA
                                </button>
                            </div>
                        )}

                        {confirmationMessage && (
                            <div className="mt-4 p-4 bg-green-100 border border-green-300 rounded">
                                <p>{confirmationMessage}</p>
                            </div>
                        )}
                    </div>
                    <div className="w-full lg:w-1/2 map-container">
                        {isLoaded && (
                            <div id="map" style={{ height: '100%', width: '100%' }}></div>
                        )}
                    </div>
                </div>
            </main>
            <Footer />
            <FooterCopyright />
        </div> 
    );
};

export default FretzHomePage;
