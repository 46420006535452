import React from 'react';

const FooterCopyright = () => {
  return (
      <div className="container mx-auto">
        <br/>
        <p>&copy; 2024 Fretz. Todos os direitos reservados. </p>
        <br/>
      </div>
  );
};

export default FooterCopyright;
