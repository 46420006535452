import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {
    const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);
    const mobileNavRef = useRef(); 
    const hamburgerButtonRef = useRef();

    const toggleMobileNav = () => {
        setIsMobileNavVisible(prevState => !prevState);
    };

    const handleClickOutside = (event) => {
        if (mobileNavRef.current && !mobileNavRef.current.contains(event.target) &&
            !hamburgerButtonRef.current.contains(event.target)) {
            setIsMobileNavVisible(false);
        }
    };

    useEffect(() => {
        const addClickListener = () => {
            document.addEventListener('mousedown', handleClickOutside);
        };

        const removeClickListener = () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

        addClickListener();
        return () => removeClickListener();
    }, []);

    return (
        <header className="bg-gradient-to-b from-fretz-start to-fretz-end text-white p-4 shadow-md">
            <ToastContainer />
            <div className="container mx-auto flex justify-between items-center">
                <Link to="/">
                    <img src="/fretz-logo-light.png" alt="Fretz" className="h-8 bright-4000" />
                </Link>

                <button onClick={toggleMobileNav} className="hamburger-button block lg:hidden" ref={hamburgerButtonRef}>
                    <Bars3Icon className="h-6 w-6 text-white" />
                </button>

                {/* Menu para telas maiores */}
                <nav className="hidden lg:flex menuwide">
                    <ul className="flex space-x-12">
                        <li><Link to="/">Simular</Link></li>
                        <li><Link to="/como-funciona">Como funciona</Link></li>
                        <li><Link to="/precos">Preços</Link></li>
                        <li><Link to="/regioes-atendidas">Regiões Atendidas</Link></li>
                    </ul>
                </nav>
            </div>

            {/* Menu lateral para telas menores */}
            <div className={`mobile-nav ${isMobileNavVisible ? 'visible' : ''}`} ref={mobileNavRef}>
                <ul>
                    <li><Link to="/">Simular</Link></li>
                    <li><Link to="/como-funciona">Como funciona</Link></li>
                    <li><Link to="/precos">Preços</Link></li>
                    <li><Link to="/regioes-atendidas">Regiões Atendidas</Link></li>
                </ul>
            </div>
        </header>
    );
};

export default Header;
