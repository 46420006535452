import React from 'react';

const Footer = () => {
  return (
    <footer className='bg-gradient-to-b from-fretz-start to-fretz-end text-white p-4'>
      <div className="container mx-auto py-10 columns-4 text-left">
            <div>
              <ul>
                <li>FRETZ</li>
                <li>
                  <a href="/">Sobre nós</a>
                </li>
                <li>
                  <a href="/">Equipe</a>
                </li>
                <li>
                  <a href="/">Carreiras</a>
                </li>
                <li>
                  <a href="/">Imprensa</a>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>ENTREGADOR</li>
                <li>
                  <a href="/">Seja um Entregador</a>
                </li>
                <li>
                  <a href="/">Calculadora de Ganhos</a>
                </li>
                <li>
                  <a href="/">Minha Conta</a>
                </li>
                <li>
                  <a href="/">Veículos Permitidos</a>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>SERVIÇOS</li>
                <li>
                  <a href="/">Fretz Agora</a>
                </li>
                <li>
                  <a href="/">Fretz Office</a>
                </li>
                <li>
                  <a href="/">Fretz Econômico</a>
                </li>
                <li>
                  <a href="/">Fretz Cargo</a>
                </li>
                <li>
                  <a href="/">Fretz Multi</a>
                </li>
                
              </ul>
            </div>
            <div>
              <ul>
                <li>ATENDIMENTO</li>
                <li>
                  <a href="/">Suporte ao Cliente</a>
                </li>
                <li>
                  <a href="/">Suporte ao Entregador</a>
                </li>
                <li>
                  <a href="/">Ouvidoria</a>
                </li>
                <li>
                  <a href="/">Fale com o CEO</a>
                </li>
                
              </ul>
            </div>
        </div>
      <br />
    </footer>
  );
};

export default Footer;
